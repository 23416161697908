import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  @Input() title: string = "Page non trouvée";
  @Input() code: string = "404";
  @Input() message: string = "La page que vous demandez, n'existe pas.";
  
  constructor() { }

  ngOnInit(): void {
  }

  onBackToLaposte(){
    window.location.href = environment.backToLaposte;
  }
}
