import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  image_logo = "assets/img/logo_laposte_pro.png";
  constructor() { }

  ngOnInit(): void {
  }

}
