export const environmentBase = {
  applicationName: 'Indemnity',
  backToLaposte: 'https://laposte.fr',
  basicAuth: 'Basic cm9vdDpyb290',
  dataPerBic: {
    AD: {iban: 'AD', length: 24},
    AT: {iban: 'AT', length: 20},
    BE: {iban: 'BE', length: 16},
    BG: {iban: 'BG', length: 22},
    BL: {iban: 'FR', length: 27},
    CH: {iban: 'CH', length: 21},
    CY: {iban: 'CY', length: 28},
    CZ: {iban: 'CZ', length: 24},
    DE: {iban: 'DE', length: 22},
    DK: {iban: 'DK', length: 18},
    EE: {iban: 'EE', length: 20},
    ES: {iban: 'ES', length: 24},
    FI: {iban: 'FI', length: 18},
    FR: {iban: 'FR', length: 27},
    GB: {iban: 'GB', length: 22},
    GG: {iban: 'GB', length: 22},
    GF: {iban: 'FR', length: 27},
    GI: {iban: 'GI', length: 23},
    GP: {iban: 'FR', length: 27},
    GR: {iban: 'GR', length: 27},
    HR: {iban: 'HR', length: 21},
    HU: {iban: 'HU', length: 28},
    IE: {iban: 'IE', length: 22},
    IM: {iban: 'GB', length: 22},
    IS: {iban: 'IS', length: 26},
    IT: {iban: 'IT', length: 27},
    JE: {iban: 'GB', length: 22},
    LI: {iban: 'LI', length: 21},
    LT: {iban: 'LT', length: 20},
    LU: {iban: 'LU', length: 20},
    LV: {iban: 'LV', length: 21},
    MC: {iban: 'MC', length: 27},
    MF: {iban: 'FR', length: 27},
    MQ: {iban: 'FR', length: 27},
    MT: {iban: 'MT', length: 31},
    NL: {iban: 'NL', length: 18},
    NO: {iban: 'NO', length: 15},
    PL: {iban: 'PL', length: 28},
    PM: {iban: 'FR', length: 27},
    PT: {iban: 'PT', length: 25},
    RE: {iban: 'FR', length: 27},
    RO: {iban: 'RO', length: 24},
    SE: {iban: 'SE', length: 24},
    SI: {iban: 'SI', length: 19},
    SK: {iban: 'SK', length: 24},
    SM: {iban: 'SM', length: 27},
    VA: {iban: 'VA'},
    YT: {iban: 'FR', length: 27}
  },
  messages: {
    IBAN_BIC_INVALID: 'Le format BIC/IBAN n\'est pas valide',
    BIC_REQUIRED : 'Champs BIC Obligatoire',
    BIC_PATTERN_INVALID: 'La valeur saisie ne correspond pas à un code BIC valide',
    IBAN_REQUIRED : 'Champs IBAN Obligatoire',
    INVALID_INDEMNITY : 'La référence de votre dossier n’est pas valide ou a déjà été utilisée. Si ce n’est pas le cas, merci de contacter le service client par mail à l’adresse mon.indemnisation@laposte.fr',
    INVALID_TICKET : 'La référence du dossier n\'est pas valide',
    ERROR_KAFKA : 'Nous rencontrons un problème technique, merci de réessayer ultérieurement',
    INTERNAL_ERROR : 'Nous rencontrons un problème technique, merci de réessayer ultérieurement ou contacter le service client par mail à l’adresse mon.indemnisation@laposte.fr\'',
    L_INDEMNISATION_N_EST_PAS_VALIDE: 'La référence de votre dossier n’est pas valide ou a déjà été utilisée. Si ce n’est pas le cas, merci de contacter le service client par mail à l’adresse mon.indemnisation@laposte.fr',
    L_INDEMNISATION_A_EXPIR_E: 'Ce lien a expiré. Afin de procéder à votre indemnisation dans les plus brefs délais, vous serez indemnisé par lettre chèque qui vous sera envoyée par courrier. Le montant de votre indemnisation reste inchangé.',
    L_INDEMNISATION_N_EST_PAS_ELLIGIBLE: 'L\'indemnisation n\'est pas elligible.'
  }
};
