import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
// import { TestComponent } from './test/test.component';

const routes: Routes = [
  {path: 'indemnity', loadChildren: () => import('./indemnity/indemnity.module').then(m => m.IndemnityModule)},
  // { path:'', component: TestComponent},
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
