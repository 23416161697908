<footer id="footer" class="container-fluid nopadding hidden-print" role="contentinfo">
  <div class="container-fluid nopadding">
    <div class="border-yellow"></div>
  </div>

  <section class="container-fluid nopadding bggreydark section-footer_bas">
    <div class="container row container--footer">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-3 text-center">
        <div class="logo_lpb_footer">
          <img title="La Poste" alt="Retour à l'accueil"
               class="img-responsive"
               src="/assets/img/logo.png">
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-7 text-left link_legal">
        <div class="col-xs-12 col-sm-5 col-md-5 link_legal_link">
          <a href="https://www.assistant-courrier.laposte.fr/information-sur-les-cookies">
            <div>
              <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
              Informations sur les cookies
            </div>
          </a>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-3 link_legal_link">
          <a href="https://www.assistant-courrier.laposte.fr/mentions-legales">
            <div>
              <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
              Mentions légales
            </div>
          </a>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-4 link_legal_link">
          <a href="https://www.laposte.fr/politique-de-protection-des-donnees">
            <div>
              <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
              Politique de confidentialité
            </div>
          </a>
        </div>

      </div>

      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 neutralite">
        <a target="_blank" href="https://laposte.fr/neutralitecarbone">
          <img title="La Poste : neutre en Co2" alt="La Poste : neutre en Co2"
               src="/assets/img/EcoLogic2-Neutralite_Carbone-LP_RESERVE.png">
        </a>
      </div>
    </div>
  </section>
</footer>
z
