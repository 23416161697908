<header role="banner" class="container-fluid">
  <nav id="header" class="navbar navbar-default navbar-fixed-top" role="navigation">

    <!-- MENU LA POSTE -->
    <div id="laposte-bar" class="container-fluid bgblack borderyellow nopadding nav--container">

      <div class="menu-metanav-container container">
        <div class="metanav-first"><a href="https://www.laposte.fr" title="laposte.fr" target="_self">
          laposte.fr
        </a></div>
        <nav class="menu-metanav-principal">
          <button type="button">Entreprises et Collectivités</button>
          <ul class="menu-metanav">
            <li><a href="https://www.laposte.fr/" title="" target="_self">
              Particuliers
            </a></li>
            <li><a href="https://www.laposte.fr/professionnel" title="" target="_self">
              Professionnels
            </a></li>
            <li class=""><a href="https://www.laposte.fr/entreprise-collectivites"
                                           title="" target="_self">
              Entreprises et Collectivités
            </a></li>
            <li><a href="https://www.laposte.fr/toutsurletimbre" title="" target="_self">
              Collectionneurs
            </a></li>
          </ul>
          <div class=" metanav-more">
            <button type="button">Le Groupe La Poste</button>
            <ul class="menu-metanav-child">
              <li><a href="https://www.labanquepostale.fr/accueil.html"
                     title="La Banque Postale (nouvelle fenêtre)" target="_blank">
                La Banque Postale
              </a></li>
              <li><a href="http://www.chronopost.fr/transport-express/livraison-colis"
                     title="Chronopost (nouvelle fenêtre)" target="_blank">
                Chronopost
              </a></li>
              <li>
                <a
                  href="https://groupelaposte.solution.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=cl&a.si=322&a.te=6243&a.ycp=&a.ra=[RANDOM]&g.lu=https%3A%2F%2Fwww.lapostemobile.fr%2F%3Futm_source%3Dlaposte%26utm_medium%3Dpartenaire%26utm_term%3D201609-laposte-hp-boutonplus%26utm_content%3Dhp%26utm_campaign%3D201609%26idpdv%3D32852%26part%3Dlaposte%26ref%3D201609-laposte-hp-boutonplus%0A"
                  title="La Poste Mobile (nouvelle fenêtre)" target="_blank">
                  La Poste Mobile
                </a>
                <hr>
              </li>
              <li><a href="https://www.docaposte.com/" title="Docaposte (nouvelle fenêtre)"
                     target="_blank">
                Docaposte
              </a></li>
              <li><a href="http://www.mediapost.fr/" title="Mediapost (nouvelle fenêtre)"
                     target="_blank">
                Mediapost
              </a></li>
              <li><a href="http://www.viapost.fr/" title="Viapost (nouvelle fenêtre)" target="_blank">
                Viapost
              </a></li>
              <li><a href="https://www.dpd.com/group/fr/" title="DPD Group (nouvelle fenêtre)"
                     target="_blank">
                DPD Group
              </a></li>
              <li><a href="http://www.asendia.com/en/" title="Asendia (nouvelle fenêtre)"
                     target="_blank">
                Asendia
              </a>
                <hr>
              </li>
              <li><a href="https://www.groupelaposte.com/fr"
                     title="Le Groupe La Poste (nouvelle fenêtre)" target="_blank">
                Le Groupe La Poste
              </a></li>
              <li><a href="http://www.laposterecrute.fr/"
                     title="Espace recrutement La Poste (nouvelle fenêtre)" target="_blank">
                Espace recrutement La Poste
              </a></li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <div id="navigation_cookies" hidden>
      <p>
        En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies pour vous
        proposer des
        contenus et services adaptés à vos centres d'intérêts.
      </p>
      <a href="/information-sur-les-cookies">En savoir plus pour gérer ces paramètres.</a>

      <div class="navigation-close">
        &times;
      </div>
    </div>

    <div class="nav--site container" id="entreprisebar">
      <div class="nav--logo-menu" aria-label="Accueil">
        <a href="/">
          <img style="width: 100px" src="/assets/img/logo.png"
               alt="La Poste" title="Retour à l'accueil">
        </a>
      </div>
    </div>
  </nav>
</header>
