
    <div class="card pl-5 pr-5 pt-1">
      <h1 class="titre-1 m-0 ">{{title}}<hr class="mt-3"></h1>
      <div class="row text-center">
        <div class="col-md-12 text-404">{{code}}</div>
        <div class="col-md-12 mb-4 text-page">{{message}}</div>
        <div class="col-md-12 m-0 mb-4">
            <button type="button" (click)="onBackToLaposte()" class="btn btn-primary2 text-right p-3"><span class="mr-2" >Retour sur La Poste</span></button>
          </div>
      </div>
    </div>
  
  