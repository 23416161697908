import {environmentBase} from './environment.base';
import {Config} from './config';

export const environment: Config = {
  production: false,
  env: 'sacc',
  canal: 'intranet',
  host: {
    u3: {
      internet: 'https://imp-u3-acc.net.extra.laposte.fr',
      intranet: 'https://u3-imp-sacc.paas-04.build.net.intra.laposte.fr'
    },
    u4: {
      internet: 'https://imp-u4-acc.net.extra.laposte.fr',
      intranet: 'https://route-u4-imp-sacc.paas-04.build.net.intra.laposte.fr'
    }
  },
  ...environmentBase
};
