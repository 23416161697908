import { BrowserModule, Title } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule} from '@angular/common/http';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { TagCommanderService, WindowRef } from 'ngx-tag-commander';
import { environment } from 'src/environments/environment';
import { GraphQLModule } from './graphql.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FooterComponent} from './components/footer/footer.component';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    NotFoundPageComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    GraphQLModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
  providers: [
    Title,
    WindowRef
  ],
  exports: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(tcService: TagCommanderService) {
    tcService.setDebug(true);
    // tcService.addContainer('tc_1491_6', environment.pdm.tc_1491_6, 'body'); // Replace URL by the one of your container
    // tcService.addContainer('tc_1491_13', environment.pdm.tc_1491_13, 'body');
  }
 }
